import { gql } from '@apollo/client';

import { LOCALIZED_STRING_FRAGMENT } from '@store/fragments';

export const pollMutations = {
	votePoll: gql`
		${LOCALIZED_STRING_FRAGMENT}
		mutation votePoll($optionIDs: [ID!]!, $contentID: ID!) {
			pollMutations {
				votePoll(input: { optionIDs: $optionIDs, contentID: $contentID }) {
					isVoted
					totalVotes
					options {
						... on CustomPollOption {
							optionID
							optionText {
								...localizedString
							}
							pictureURL
							votes
							votesPercentage
							isChosen
						}
						... on TagPollOption {
							optionID
							tag {
								id
								title {
									...localizedString
								}
								logo {
									url
								}
								type
							}
							votes
							votesPercentage
							isChosen
						}
					}
				}
			}
		}
	`,
};
