import { FetchResult } from '@apollo/client';
import { CustomPoll, PollMutations } from '@ui-kit/main-api-types';

import { client } from '@apollo-client-v2';
import { useUserStore } from '@store/user';

import { pollMutations } from './mutations';
import { pollQueries } from './queries';

export const refreshPoll = (pollId: string, locale: string): CustomPoll => {
	const { user, getDeviceId } = useUserStore.getState();
	let deviceId = user?.deviceId;
	if (!deviceId) {
		getDeviceId();
	}
	deviceId = useUserStore.getState().user.deviceId;

	return client
		.query({
			query: pollQueries.refreshPoll,
			variables: {
				pollHruOrId: pollId,
				lang: locale?.toUpperCase(),
			},
			context: {
				headers: {
					'x-auth-token': user.authToken,
					'x-device-id': deviceId,
				},
			},
		})
		.then(res => res?.data?.pollQueries?.get);
};

export const getPollById = (pollId: string, locale: string): CustomPoll => {
	const userState = useUserStore.getState().user;
	const { user, getDeviceId } = useUserStore.getState();
	let deviceId = user?.deviceId;
	if (!deviceId) {
		getDeviceId();
	}
	deviceId = useUserStore.getState().user.deviceId;
	return client
		.query({
			query: pollQueries.getPollById,
			variables: {
				pollHruOrId: pollId,
				lang: locale?.toUpperCase(),
			},
			context: {
				headers: {
					'x-auth-token': userState.authToken,
					'x-device-id': deviceId,
					'X-Language': locale?.toLowerCase(),
				},
			},
		})
		.then(res => res?.data?.pollQueries?.get);
};

export const votePoll = async ({
	contentID,
	optionIDs,
}: {
	contentID: string;
	optionIDs: string[];
}): Promise<
	FetchResult<{
		pollMutations: PollMutations;
	}>
> => {
	const { user, getDeviceId } = useUserStore.getState();
	let deviceId = user?.deviceId;
	if (!deviceId) {
		getDeviceId();
	}
	deviceId = useUserStore.getState().user.deviceId;

	const voteResult = await client.mutate({
		mutation: pollMutations.votePoll,
		variables: {
			contentID,
			optionIDs,
		},
		context: {
			headers: {
				'x-auth-token': user?.authToken,
				'x-device-id': deviceId,
			},
		},
		errorPolicy: 'all',
	});

	return voteResult;
};
